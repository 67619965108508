// 1024*768
// 1280*800
// 1280*1024
// 1366*768
// 1440*900
// 1536*864
// 1600*900
// 1920*1080
// 2560*1440


$colorBody: #f4f7fc;
$colorRed: #E41500;
$colorGrey: #292939;
$colorWhite: #FFFFFF;


@import "../../../../node_modules/reset-css/sass/reset";
@import "../../../../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: "a_AvanteBs";
  font-style: normal;
  font-weight: 400;
  src: local(""), 
  // url("../fonts/a_avantebs.woff2") format("woff2"),
  url("../fonts/a_avantebs.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/a_avantebs.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "a_AvanteLt";
  font-style: normal;
  font-weight: 400;
  src: local(""), 
  // url("../fonts/a_avantelt-light.woff2") format("woff2"),
  url("../fonts/a_avantelt-light.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/a_avantelt-light.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
.container {
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
  @media screen and (min-width: 1025px) and (max-width: 1535px) {
    max-width: 970px;
    padding-right: 0;
    padding-left: 0;
  }
  @media screen and (min-width: 1536px) {
    max-width: 1440px;
  }
}

.col-xs-five,
.col-sm-five,
.col-md-five,
.col-lg-five {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-five {
    width: 20%;
    float: left;
    @media screen and (max-width: 420px) {
        width: 50%;
    }
    &:first-child {
      @media screen and (max-width: 420px) {
        width: 100%;
      }
    }

}

@media (min-width: 768px) {
    .col-sm-five {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-five {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-five {
        width: 20%;
        float: left;
    }
}

button:active,
button:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}

body {
  background-color: $colorBody;
  font-family: a_AvanteLt;
  font-style: normal;
}
a {
  font-family: a_AvanteLt;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 17px;
  transition: all 320ms ease;
  text-decoration: none;
}
p {
  font-family: a_AvanteLt;
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 37px;

  @media screen and (max-width: 420px) {
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (min-width: 421px) and (max-width: 1537px) {
    font-size: 18px;
    line-height: 27px;
  }
}

h2 {
    font-family: a_AvanteBs;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 40px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
}

h3 {
    font-family: a_AvanteBs;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
}

a.ebtn,
button.ebtn {
  background: $colorWhite;
  color: #202126;
  font-family: a_AvanteLt;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  padding: 22px 42px;
  white-space: nowrap;
  text-decoration: none;
  border: solid 1px transparent;
  cursor: pointer;
  @media screen and (min-width: 1537px) {
    font-size: 14px;
  }
}
button.ebtn {
  transition: color 0.3s, background-color 0.3s;
  margin-top: 50px;
}

a.ebtn:hover,
button.ebtn:hover {
  background-color: transparent;
  border: solid 1px $colorWhite;
  color: $colorWhite;
  transition: color 0.3s, background-color 0.3s;
}

button.ebtn:hover {
  transition: color 0.3s, background-color 0.3s;
}

#cookie_notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 9999;
  bottom: 0px;
  left: 50%;
  width: 930px;
  max-width: 90%;
  transform: translateX(-50%);
  opacity: 0;
  transition: .3s;
  padding: 28px 50px;
  background-color: $colorWhite;
  border-bottom: $colorGrey 6px solid;
  & p{
    margin: 0;
    font-family: a_AvanteLt;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: $colorGrey;
  }
  & .cookie_accept {
    background-color: $colorRed;
    width: 200px;
    text-align: center;
    padding: 14px 0;
    color: $colorWhite;
    border: $colorRed 1px solid;
    transition: .3s;
    &:hover {
      background-color: $colorWhite;
      color: $colorRed;
      transition: .3s;
    }
  }
  &.show {
    @media (max-width: 575px){
        display: block;
        text-align: left;
    }
    @media (min-width: 576px){
        // display: flex;
        opacity: 1;
        transition: .3s;
    }
  }
}

#block-1 {
  background-color: $colorRed;
  background-image: url(../images/bg/block-1.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  color: $colorWhite;
  width: 100%;
  height: 100vh;
  @media screen and (min-width: 421px) {
    display: flex;
  }
  & h1 {
    font-family: a_AvanteBs;
    font-style: normal;
    font-weight: 800;
    font-size: 80px;
    line-height: 84px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin-top: 90px;
    margin-bottom: 40px;
    @media screen and (max-width: 420px) {
      font-size: 36px;
      line-height: 38px;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 421px) and (max-width: 1536px) {
      font-size: 48px;
      line-height: 50px;
    }
  }
  & a {
    color: $colorWhite;
    &.ebtn {
      color: $colorGrey;
      margin-top: 22px;
      &:hover {
        background-color: transparent;
        border: solid 1px $colorWhite;
        color: $colorWhite;
      }
    }
  }
  & .block-menu {
    width: 150px;
    height: 100%;
    background: linear-gradient(180deg, #ec2b18 0%, #da2310 100%);
    position: relative;
    flex-shrink: 0;
    @media screen and (max-width: 420px) {
      width: 100%;
      height: 60px;
      background: $colorGrey;
    }
    @media screen and (min-width: 421px) and (max-width: 1536px) {
      width: 97px;
    }
    & #nav-ico {
      content: url(../images/icons/burger.svg);
      width: 24px;
      height: 16px;
      position: absolute;
      top: 141px;
      left: 63px;
      cursor: pointer;
      @media screen and (max-width: 420px) {
        top: 20px;
        left: 20px;
      }
      @media screen and (min-width: 421px) and (max-width: 1536px) {
        left: 36px;
      }
    }
    & .slide-menu {
      background: linear-gradient(180deg, #ec2b18 0%, #292939 100%);
      width: 585px;
      height: 100%;
      padding: 257px 0 0 63px;
      position: absolute;
      left: -110%;
      display: none;
      z-index: 10;
      @media screen and (max-width: 420px) {
        height: 100vh;
        width: 100%;
        padding: 85px 0 0 20px;
        background: $colorGrey;
      }
      @media screen and (min-width: 421px) and (max-width: 1024px) {
        width: 385px;
        padding-top: 177px;
      }
      & ul {
        margin: 0;
        padding: 0;
        & li {
          & a {
          font-family: a_AvanteLt;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 56px;
          text-decoration: none;
          @media screen and (max-width: 420px) {
            line-height: 46px;
          }
        }
      }
    }
      & .social {
        margin: 40px 0 100px;
        @media screen and (max-width: 420px) {
          margin-bottom: 80px;
        }
        & a {
          margin-right: 20px;
          &.fb {
            content: url(../images/icons/fb.svg);
            height: 18px;
          }
          &.vk {
            content: url(../images/icons/vk.svg);
            width: 18px;
            margin-bottom: 3px;
          }
          &.youtube {
            content: url(../images/icons/youtube.svg);
            width: 18px;
          }
          &.instagram {
            content: url(../images/icons/instagram.svg);
            width: 18px;
            height: 18px;
          }
        }
      }
      & .menu-close {
        content: url(../images/icons/close.svg);
        width: 16px;
        height: 16px;
        position: absolute;
        top: 141px;
        left: 63px;
        cursor: pointer;
        @media screen and (max-width: 420px) {
          top: 20px;
          left: 20px;
        }
      }
    }
  }
  & .container {
    @media screen and (max-width: 420px) {
      padding: 0 20px;
    }
    & .block-content {
      position: relative;
      padding-top: 120px;
      margin-left: -87px;
      width: 65%;
      @media screen and (max-width: 420px) {
        width: 100%;
        margin: 0;
        padding: 0;
      }
      @media screen and (min-width: 421px) and (max-width: 1024px) {
        margin-left: 44px;
        width: 70%;
      }
      @media screen and (min-width: 1025px) and (max-width: 1440px) {
        margin-left: -44px;
      }
      @media screen and (min-width: 1536px) and (max-width: 1920px) {
        margin-left: 44px;
      }
      & .block-top {
        display: flex;
        @media screen and (max-width: 420px) {
          display: none;
        }
      }
      & .block-btn {
        margin-top: 22px;
        margin-left: 41px;
      }
    }
    & .block-contacts {
      position: absolute;
      bottom: 100px;
      margin-left: -44px;
      @media screen and (max-width: 420px) {
        margin: 0;
        width: 44%;
      }
      @media screen and (min-width: 421px) and (max-width: 1024px) {
        bottom: 70px;
        margin-left: 44px;
      }
      @media screen and (min-width: 1536px) and (max-width: 1920px) {
        margin-left: 44px;
      }
      & a {
        &.phone {
          position: relative;
          margin-left: 30px;
          margin-right: 78px;
          @media screen and (max-width: 420px) {
            margin-right: 0;
          }
          &::before {
            content: url(../images/icons/phone.svg);
            width: 18px;
            height: 18px;
            position: absolute;
            left: -30px;
          }
        }
        &.email {
          position: relative;
          @media screen and (max-width: 420px) {
            left: 30px;
          }
          &::before {
            content: url(../images/icons/mail.svg);
            position: absolute;
            width: 18px;
            height: 18px;
            left: -30px;
          }
        }
      }
    }
    & .mob_btn {
      @media screen and (max-width: 420px) {
        display: block;
        margin-top: 44px;
        & a.ebtn {
          font-size: 14px;
          line-height: 15px;
          padding: 18px 20px;
        }
      }
      @media screen and (min-width: 421px) {
        display: none;
      }
    }
    & .key {
      content: url(../images/bg/key.png);
      width: 494px;
      height: 589px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -294px;
      @media screen and (max-width: 420px) {
        width: 147px;
        height: 147px;
        top: auto;
        margin: 0;
      }
      @media screen and (min-width: 421px) and (max-width: 1024px) {
        margin-right: -150px;
      }
    }
  }
}


#block-2 {
  @media screen and (max-width: 420px) {
    padding: 0 20px;
  }
  @media screen and (min-width: 421px) and (max-width: 1024px) {
    padding: 0 97px;
  }
  & .container {
    padding: 50px 0 90px ;
    @media screen and (min-width: 421px) {
      display: flex;
      padding: 150px 0 150px;
    }
    @media screen and (min-width: 1536px) and (max-width: 1920px) {
      padding-left: 130px;
    }
  }
}

.block-img {
  margin-right: 100px;
  @media screen and (max-width: 420px) {
    margin: 0;
    text-align: center;
  }
}
.block-info {
  padding-top: 30px;
  @media screen and (max-width: 420px) {
    margin-top: 40px;
  }
}

#block-2 h2 {
  font-family: a_AvanteBs;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: $colorGrey;
  @media screen and (max-width: 420px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media screen and (max-width: 1537px) {
    font-size: 36px;
    line-height: 43px;
  }
}

#block-3 {
  @media screen and (max-width: 420px) {
    display: none;
  }
  position: relative;
  display: block;
  background-color: $colorRed;
  width: 100%;
  height: 100vh;
}
#block-3 .container {
  padding-right: 0;
  padding-left: 0;
  @media screen and (max-width: 1537px) {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  @media screen and (min-width: 1536px) {
    max-width: 100%;
    padding-left: 250px;
  }
}
#block-3 .image-block {
  height: 80vh;
}
#block-3 .image-block img {
  width: 100%;
  height: 80vh;
}
.white {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 47%;
  height: 34vh;
  background-color: $colorWhite;
  z-index: 1001;
}
.block_nav {
  display: flex;
  min-width: 0;
  justify-content: center;
  align-items: center;
  font-family: a_AvanteLt;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #292939;
  margin-left: 98px;
  @media screen and (min-width: 1024px) {
    margin-right: 50px;
    font-size: 0.8rem;
  }
  @media screen and (min-width: 1280px) {
    margin-right: 145px;
    font-size: 0.8rem;
  }
  @media screen and (min-width: 1366px) {
    margin-right: 145px;
    font-size: 0.8rem;
  }
  @media screen and (min-width: 1440px) {
    margin-right: 145px;
  }
  @media screen and (min-width: 1536px) {
    margin-left: 100px;
    margin-right: 145px;
  }
  @media screen and (min-width: 1600px) {
    margin-left: 90px;
    margin-right: 145px;
  }
  @media screen and (min-width: 1920px) {
    margin-left: 250px;
    margin-right: 145px;
  }
  @media screen and (min-width: 2560px) {
    margin-left: 580px;
    margin-right: 145px;
  }
}

.block_nav span {
  font-family: a_AvanteBs;
  font-weight: 800;
}

.desc-block {
  position: absolute;
  top: 100px;
  right: 250px;
  width: 400px;
}

.desc-block::after {
  content: "";
  position: absolute;
  top: 50px;
  right: -250px;
  width: 200px;
  height: 2px;
  background-color: $colorRed;
}


#block-3_mob {
  @media screen and (max-width: 420px) {
    display:block;
  }
  @media screen and (min-width: 421px) {
    display:none;
  }
  & .mob_slider{
    position: relative;
    & p.bg_red {
      background-color: $colorRed;
      padding: 40px 20px;
      color: $colorWhite;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
    }
    & img {
      width: 100%;
      height: 260px;
      background-size: cover;
    }
    & .bg_white {
      background-color: $colorWhite;
      padding: 40px 20px;
      & p {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        margin: 0;
        &.ps {
          font-weight: 800;
          margin-top: 20px;
        }
      }
    }
    & .slick-arrow {
      position: absolute;
      width: 33px;
      height: 33px;
      background-color: $colorRed;
      border: none;
      transition: .3s;
      font-size: 0;
      z-index: 1;
      &:hover {
        background-color: $colorGrey;
        transition: 0.3s;
      }
      &.slick-prev {
        left: 0;
        top: 50%;
        margin-top: -80px;
        &::after {
          position: relative;
          display: block;
          left: 7px;
          content: " ";
          background: url("../images/icons/prev_arrow.svg") 0 0 no-repeat;
          background-size: 7px 12px;
          height: 12px;
          width: 7px;
        }
      }
      &.slick-next {
        right: 0;
        top: 50%;
        margin-top: -80px;
        &::after {
          position: relative;
          display: block;
          left: 7px;
          content: " ";
          background: url("../images/icons/next_arrow.svg") 0 0 no-repeat;
          background-size: 7px 12px;
          height: 12px;
          width: 7px;
        }
      }
    }
  }
}

#block-4 {
    padding-top: 140px;
    overflow: hidden;
    @media screen and (max-width: 420px) {
      padding-top: 40px;
    }
    & .block_1 {
        margin-bottom: 70px;
        padding-bottom: 120px;
        position: relative;
        @media screen and (max-width: 420px) {
          margin-bottom: 40px;
          padding-bottom: 0;
        }
        & .container {
            position: relative;
            z-index: 2;
            @media screen and (max-width: 420px) {
              padding: 0 20px;
            }
            & h2 {
                margin-bottom: 80px;
                @media screen and (max-width: 420px) {
                  font-size: 24px;
                  line-height: 29px;
                  margin-bottom: 34px;
                  &.m_low {
                    text-transform: none;
                  }
                }
            }
            & ul {
              @media screen and (max-width: 420px) {
                padding: 0;
                margin: 0;
              }
                li {
                    margin-bottom: 14px;
                    margin-left: 60px;
                    position: relative;
                    @media screen and (max-width: 420px) {
                      margin-bottom: 26px;
                    }
                    &::before {
                        position: absolute;
                        content: url(../images/icons/ularrow.svg);
                        width: 32px;
                        height: 32px;
                        background-color: $colorRed;
                        padding-top: 5px;
                        padding-left: 14px;
                        margin-left: -62px;
                    }
                }
            }
        }
        & .block1_bg {
            position: absolute;
            content: url('../images/bg/section4bg.png');
            width: 48%;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 1;
            @media screen and (max-width: 420px) {
              width: 100%;
              height: auto;
              top: 59%;
            }
        }
    }
    & .block_2 {
        margin-bottom: 180px;
        position: relative;
        @media screen and (max-width: 420px) {
          margin-bottom: 40px;
        }
        & p {
            font-size: 16px;
            line-height: 26px;
        }
        & button.ebtn {
            background-color: $colorRed;
            color: $colorWhite;
            &:hover {
                background-color: transparent;
                color: $colorRed;
                border-color: $colorRed;
            }
        }
        & .container {
          @media screen and (max-width: 420px) {
            padding: 300px 20px 0;
          }
          & h2 {
            @media screen and (max-width: 420px) {
              font-size: 24px;
              line-height: 29px;
              margin-bottom: 20px;
            }
          }
          & p {
            @media screen and (max-width: 420px)  {
              font-size: 16px;
              line-height: 26px;
            }
          }
        }
        & .img_block {
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            @media screen and (max-width: 420px) {
              width: 100%;
              height: 255px;
            }
            & .red_bg {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: $colorRed;
                top: -70px;
                left: 0;
                @media screen and (max-width: 420px) {
                  top: -20px;
                }
            }
            & .b2img {
                background-image: url(../images/bg/b2img.png);
                background-size: cover;
                background-repeat: no-repeat;
                z-index: 2;
                position: relative;
                width: 100%;
                height: 100%;
                margin-left: 70px;
                @media screen and (max-width: 420px) {
                  margin-left: 20px;
                }
            }
        }
    }
    & .block_3 {
        position: relative;
        padding: 80px 0;
        @media screen and (max-width: 420px) {
          padding: 0;
        }
        & p {
            font-size: 16px;
            line-height: 26px;
        }
        & .block_video {
            position: absolute;
            width: 50%;
            height: 100%;
            margin-top: -80px;
            @media screen and (max-width: 420px) {
              width: 100%;
              margin: 0;
              height: 210px;
            }
            & iframe {
                width: 100%;
                height: 100%;
            }
        }
        & .container {
          @media screen and (max-width: 420px) {
            padding: 250px 20px 0;
          }
          & h2 {
            @media screen and (max-width: 420px) {
              font-size: 24px;
              line-height: 29px;
              margin-bottom: 40px;
            }
          }
          & p {
            @media screen and (max-width: 420px) {
              font-size: 16px;
              line-height: 26px;
            }
          }
        }
    }
}


#block-5 {
  @media screen and (max-width: 420px) {
    overflow: hidden;
  }
  & .container {
    @media screen and (max-width: 1440px) {
      max-width: 100%;
    }
    & .slider_mac_bg {
      position: relative;
      & img {
        width: 100%;
        @media screen and (max-width: 420px) {
          width: 173%;
          margin-left: -34%;
        }
      }
      & .slider_mac {
        width: 56.67%;
        height: 54.5%;
        position: absolute;
        top: 16.2%;
        left: 20.4%;
        margin: 0;
        padding: 0;
        @media screen and (max-width: 420px) {
          width: 100%;
          height: 162px;
          left: 0;
        }
        & .slick-list{
          @media screen and (max-width: 420px) {
            height: 162px;
          }
        }
        & .slick-arrow {
          @media screen and (max-width: 420px) {
            width: 33px;
            height: 33px;
          }
          &.slick-prev {
            @media screen and (max-width: 420px) {
              left: 0;
              margin-top: 0;
              &::after {
                position: relative;
                display: block;
                left: 12px;
                content: " ";
                background: url("../images/icons/prev_arrow.svg") 0 0 no-repeat;
                background-size: 7px 12px;
                height: 12px;
                width: 7px;
              }
            }
          }
          &.slick-next {
            @media screen and (max-width: 420px) {
              right: 0;
              margin-top: 0;
              &::after {
                position: relative;
                display: block;
                left: 12px;
                content: " ";
                background: url("../images/icons/next_arrow.svg") 0 0 no-repeat;
                background-size: 7px 12px;
                height: 12px;
                width: 7px;
              }
            }
          }
        }
      }
      
      & .slider_mac_info {
        position: absolute;
        bottom: 10%;
        right: 0;
        width: 40%;
        padding: 57px 45px;
        background-color: $colorRed;
        color: $colorWhite;
        @media screen and (max-width: 420px) {
          width: calc(100% + 35px);
          padding: 40px 20px;
          position: relative;
          margin-left: -15px;
        }
        @media screen and (min-width: 421px) and (max-width: 1440px) {
          right: 10%;
          padding: 50px 85px;
        }
        & p {
          font-family: a_AvanteLt;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
} 


#block-6 {
    margin-top: 77px;
    @media screen and (max-width: 420px) {
      margin-top: 40px;
    }
    @media screen and (min-width: 421px) and (max-width: 1440px) {
        overflow: hidden;
        padding-bottom: 50px;
    }
    & p {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
    }
    & .container {
      @media screen and (max-width: 420px) {
        margin: 0;
        padding: 0 20px;
      }
      & h2 {
        font-size: 24px;
        line-height: 29px;
      }
  }
    & .block_function-1 {
        margin-top: 68px;
        margin-bottom: 140px;
        position: relative;
        @media screen and (max-width: 420px) {
          margin-top: 40px;
          margin-bottom: 0;
          overflow: hidden;
        }
        & .red_bg {
            background-color: $colorRed;
            position: absolute;
            top: 0;
            left: 0;
            width: 30%;
            height: 100%;
            z-index: 1;
            @media screen and (max-width: 420px) {
              display: none;
            }
        }
        & .container {
            position: relative;
            z-index: 2;
            @media screen and (max-width: 420px) {
              margin: 0;
              padding: 0;
            }
            & .row {
              @media screen and (max-width: 420px) {
                margin: 0;
                padding: 0;
              }
              & .col-lg-4 {
                background-color: $colorRed;
                padding: 80px 90px 80px 0;
                color: $colorWhite;
                z-index: 2;
                position: relative;
                @media screen and (max-width: 420px) {
                  padding: 40px 20px;
                }
                &::before {
                    content: ' ';
                    background-image: url(../images/bg/1.svg);
                    background-size: 88px 114px;
                    position: absolute;
                    width: 88px;
                    height: 114px;
                    margin-left: -126px;
                    @media screen and (max-width: 420px) {
                      width: 40px;
                      height: 64px;
                      margin-left: 0;
                      content: ' ';
                      background-image: url(../images/bg/1.svg);
                      background-size: 40px 64px;
                      top: 20px;
                    }
                }
                & h2 {
                  margin-left: 50px;
                }
                & p {
                  @media screen and (max-width: 420px) {
                    margin-bottom: 40px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
              & .col-lg-8 {
                  background-color: $colorWhite;
                  color: $colorGrey;
                  padding: 149px 136px 0 80px;
                  position: relative;
                  @media screen and (max-width: 420px) {
                    padding: 40px 20px 166px;
                  }
                  & p {
                    @media screen and (max-width: 420px) {
                      margin-bottom: 40px;
                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                  & .macbook {
                      background-image: url(../images/bg/macbook.png);
                      width: 624px;
                      height: 436px;
                      position: absolute;
                      bottom: -150px;
                      right: -200px;
                      @media screen and (max-width: 420px) {
                        width: 294px;
                        height: 187px;
                        background-size: 294px 187px;
                        right: -100px;
                        bottom: 100px;
                      }
                  }
                }
            } 
        }
        & .white_bg {
          @media screen and (max-width: 420px) {
            display: none;
          }
          @media screen and (min-width: 421px) and (max-width: 1440px) {
              background-color: $colorWhite;
              position: absolute;
              top: 0;
              right: 0;
              width: 30%;
              height: 100%;
              z-index: 1;
          }
        }
 
    }
    & .block_function-2 {
        margin-bottom: 201px;
        @media screen and (max-width: 420px) {
          margin-bottom: 84px;
        }
        & .col-lg-4 {
            position: relative;
            &::before {
                content: ' ';
                background-image: url(../images/bg/2.svg);
                background-size: 88px 114px;
                position: absolute;
                width: 88px;
                height: 114px;
                margin-left: -126px;
                @media screen and (max-width: 420px) {
                  background-image: url(../images/bg/2.svg);
                  background-size: 40px 64px;
                  width: 40px;
                  height: 64px;
                  background-repeat: no-repeat;
                  margin-left: 0;
                  top: 30px;
                  right: 0;
                }
            }
            & .girl_img {
                position: absolute;
                content: url(../images/bg/2.png);
                top: -180px;
                left: -50px;
                z-index: 3;
                @media screen and (max-width: 420px) {
                  content: ' ';
                  background-image: url(../images/bg/2.png);
                  background-size: 100%;
                  width: 100%;
                  height: 540px;
                  top: -140px;
                }
                @media screen and (min-width: 421px) and (max-width: 1536px) {
                    left: -140px;
                }
            }
        }
        & .col-lg-8 {
            padding: 0 80px;
            @media screen and (max-width: 420px) {
              padding: 500px 10px 0;
            }
            & p {
              @media screen and (max-width: 420px) {
                margin-bottom: 40px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
        }
    }
    & .block_function-3 {
        position: relative;
        & .red_bg {
            background-color: $colorRed;
            position: absolute;
            top: 0;
            right: 0;
            width: 30%;
            height: 100%;  
            @media screen and (max-width: 420px) {
              width: 100%;
              top: 220px;
            }
        }
        & .container {
            & .col-lg-4 {
                &::before {
                  content: ' ';
                  background-image: url(../images/bg/3.svg);
                  background-size: 88px 114px;
                    position: absolute;
                    width: 88px;
                    height: 114px;
                    margin-left: -126px;
                    @media screen and (max-width: 420px) {
                      margin-left: 0;
                      content: '';
                      background-image: url(../images/bg/3.svg);
                      background-size: 40px 64px;
                      width: 40px;
                      height: 64px;
                      top: -20px;
                    }
                }
                & h2 {
                  @media screen and (max-width: 420px) {
                    margin-left: 50px;
                  }
                }
            }
            & .col-lg-8 {
                background-color: $colorRed;
                position: relative;
                &::before {
                    content: url('../images/bg/megafon.png');
                    position: absolute;
                    top: -150px;
                    left: 0;
                    @media screen and (max-width: 420px) {
                      content: '';
                      background-image: url('../images/bg/megafon.png');
                      background-size: 307px 307px;
                      width: 307px;
                      height: 307px;
                      top: -20px;
                      z-index: 3;
                    }
                }
            }
        }
    }
}

#block-7 {
    margin-top: 140px;
    margin-bottom: 140px;
    position: relative;
    @media screen and (max-width: 420px) {
      margin-top: 300px;
      margin-bottom: 40px;
    }
    &::after {
        position: absolute;
        content: url(../images/bg/section7bg.png);
        top: 0;
        right: 0;
        z-index: 1;
        height: 100%;
    }
    & .container {
      @media screen and (max-width: 420px) {
        padding: 0 20px;
      }
      & .col-lg-5 {
        @media screen and (max-width: 420px) {
          width: 100%;
        }
        @media screen and (min-width: 421px) and (max-width: 1440px) {
            width: 50%;
        }
        &.second_mob{
          @media screen and (max-width: 420px) {
            margin-top: 50px;
          }
        }
      }
      & h2 {
          margin-bottom: 80px;
          @media screen and (max-width: 420px) {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 40px;
          }
      }
      & h3 {
          margin-bottom: 40px;
      }
      & .bag_block {
          background-color: $colorRed;
          color: $colorWhite;
          padding: 80px;
          position: relative;
          z-index: 2;
          margin-top: 153px;
          @media screen and (max-width: 420px) {
            margin-top: 100px;
            padding: 40px;
          }
          &::before {
              content: url('../images/bg/handle.png');
              position: absolute;
              top: -153px;
              left: 50%;
              margin-left: -108px;
              @media screen and (max-width: 420px) {
                top: -109px;
              }
          }
          & p {
              font-family: a_AvanteLt;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 40px;
              &:last-child {
                  margin-bottom: 0;
              }
          }
      }
      & button.ebtn {
          margin-top: 33px;
          color: $colorGrey;
          background-color: $colorWhite;
          &:hover {
              background-color: transparent;
              color: $colorWhite;
              border-color: $colorWhite;
          }
      }
    }
}

footer {
    background-color: $colorWhite;
    color: #292939;
    padding: 140px 0;
    @media screen and (max-width: 420px) {
      padding: 50px 0;
      overflow: hidden;
    }
    & .container {
      @media screen and (max-width: 420px) {
        margin: 0 20px;
      }
      & .logo {
        content: url('../images/logo/logo_footer.png');
        width: 88px;
        height: 39px;
        @media screen and (max-width: 420px) {
          display: none;
        }
      }
      & .social {
          margin-top: 15px;
          margin-bottom: 30px;
      }
      & ul {
          margin: 0;
          padding: 0;
          & li{
            @media screen and (max-width: 420px) {
              margin-bottom: 25px;
            }
            & a {
                line-height: 46px;
                @media screen and (max-width: 420px) {
                  line-height: 26px;
                }
            }
          }
      }
      & a {
          font-family: a_AvanteLt;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 46px;
          color: $colorGrey;
          &:hover {
              color: $colorRed;
          }
          &.phone {
              position: relative;
              margin-right: 0;
              margin-left: 35px;
              @media screen and (max-width: 420px) {
                margin-left: 30px;
              }
              &::before {
                position: absolute;  
                content: url('../images/icons/phone_footer.svg');
                  left: -35px;
                  top: -10px;
                  @media screen and (max-width: 420px) {
                    position: absolute;
                    left: -30px;
                  }
              }
              &:hover {
                  &::before {
                      content: url('../images/icons/phone_footer_hover.svg');
                  }
              }
          }
          &.email{
              margin-left: 35px;
              top: -10px;
              position: relative;
              @media screen and (max-width: 420px) {
                margin-left: 30px;
              }
              &::before {
                  position: absolute;
                  content: url('../images/icons/mail_footer.svg');
                  left: -35px;
                  top: -10px;
                  @media screen and (max-width: 420px) {
                    left: -30px;
                  }
              }
              &:hover {
                  &::before {
                      content: url('../images/icons/mail_footer_hover.svg');
                  }  
              }
          }
          &.fb {
              content: url('../images/icons/fb_footer.svg');
              margin-right: 20px;
              margin-left: 5px;
              &:hover {
                  content: url('../images/icons/fb_footer_hover.svg');
                  margin-right: 20px;
                  margin-left: 5px;
              }
          }
          &.vk {
              content: url('../images/icons/vk_footer.svg');
              margin-right: 20px;
              margin-bottom: 0;
              &:hover {
                  content: url('../images/icons/vk_footer_hover.svg');
                  margin-right: 20px;
              }
          }
          &.youtube {
              content: url('../images/icons/youtube_footer.svg');
              margin-right: 20px;
              &:hover {
                  content: url('../images/icons/youtube_footer_hover.svg');
                  margin-right: 20px;
              }
          }
          &.instagram {
              content: url('../images/icons/instagram_footer.svg');
              &:hover {
                  content: url('../images/icons/instagram_footer_hover.svg');
                  margin-right: 20px;
              }
          }
      }
      & p.title {
          font-family: a_AvanteBs;
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          @media screen and (max-width: 420px) {
            margin: 40px 0;
          }
      }
      & .logo_mob {
        display: none;
        @media screen and (max-width: 420px) {
          display: block;
          width: 100%;
        }
        & .logo {
          content: url('../images/logo/logo_footer.png');
          width: 88px;
          height: 39px;
          @media screen and (max-width: 420px) {
            display: block;
            margin: 30px auto 0;
          }
        }
      }
    }
}







/* input */

input {
  width: 100%;
  height: 60px;
  border: none;
  margin-bottom: 25px;
  padding: 20px;
  font-family: a_AvanteLt;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 19px;
}

input,
textarea {
  color: $colorGrey;
}

textarea {
  resize: none;
}

textarea::placeholder {
  color: #afb4bc;
}

input:focus,
input:active {
  border: none;
  outline: 0;
}

input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.5s ease;
}
input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.5s ease;
}
input:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.5s ease;
}
input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.5s ease;
}
input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}
input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}
input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}
input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}

textarea::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.5s ease;
}
textarea::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.5s ease;
}
textarea:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.5s ease;
}
textarea:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.5s ease;
}
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}
textarea:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}
textarea:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}
textarea:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}




input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
/* end input */

/* label */

label {
  color: $colorWhite;
  width: 100%;
  margin-bottom: 10px;
  font-family: a_AvanteLt;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}

/* end label */

/* модалка */
#modal-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  &.open {
    visibility: visible;
    opacity: 1;
  }
}
#modal-block {
  width: 690px;
  height: 743px;
  background-color: $colorRed;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -345px;
  margin-top: -400px;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  @media screen and (max-width: 420px) {
    top: 0;
    left: 0;
    margin: 0;
    padding: 60px 20px 20px 20px;
    width: 100%;
    height: 100vh;
  }
  &.open {
    visibility: visible;
    opacity: 1;
    margin-top: -300px;
    @media screen and (max-width: 420px) {
      margin-top: 0;
    }
  }
  & .close {
    content: url(../images/icons/close.svg);
    width: 16px;
    height: 16px;
    position: absolute;
    top: 30px;
    left: 550px;
    cursor: pointer;
    @media screen and (max-width: 420px) {
      top: 20px;
      left: 20px;
    }
  }
  & .modal-build {
    height: 205px;
    width: 690px;
    margin-top: -205px;
    background: url("../images/bg/modal-build.png");
    @media screen and (max-width: 420px) {
      display: none;
    }
  }
  & form {
    margin: 60px 120px;
    @media screen and (max-width: 420px) {
      margin: 0;
    }
  }
}

/* Слайдер */
.slider-for {
  @media screen and (max-width: 1536px) {
    margin-left: 98px;
  }
}
.slider-nav {
  min-width: 0;
}

.slick-list {
  overflow: hidden;
}

.slider_mac .slick-list {
  border-radius: 15px 15px 0 0;
}

/* Лента слайдов */
.slick-track {
  display: flex;
}
.slider-nav .slick-arrow,
.slider_mac .slick-arrow {
  position: absolute;
  bottom: 0;
  width: 90px;
  height: 90px;
  background-color: $colorRed;
  border: none;
  font-size: 0;
  transition: 0.3s;
  margin: 0;
  padding: 0;
  line-height: 0;
  z-index: 1;
  &:hover {
    background-color: $colorGrey;
    transition: 0.3s;
  }
  @media screen and (max-width: 1366px) {
    width: 85px;
    height: 85px;
  }
}
/* Стрелка влево */
.slider-nav .slick-arrow.slick-prev {
  right: 90px;
  @media screen and (max-width: 1366px) {
    right: 85px;
  }
  &::after {
    position: relative;
    display: block;
    content: " ";
    left: 36px;
    background: url("../images/icons/prev_arrow.svg") 0 0 no-repeat;
    width: 18px;
    height: 30px;
    @media screen and (max-width: 1366px) {
      left: 33px;
    }
  }
}

.slider_mac .slick-arrow.slick-prev {
  left: -45px;
  margin-top: -45px;
  top: 50%;
  &::after {
    position: relative;
    display: block;
    content: " ";
    left: 36px;
    background: url("../images/icons/prev_arrow.svg") 0 0 no-repeat;
    width: 18px;
    height: 30px;
    @media screen and (max-width: 1366px) {
      left: 33px;
    }
  }
}

/* Стрелка вправо */
.slider-nav .slick-arrow.slick-next {
  right: 0;
  &::after {
    position: relative;
    display: block;
    left: 36px;
    content: " ";
    background: url("../images/icons/next_arrow.svg") 0 0 no-repeat;
    height: 30px;
    width: 18px;
    @media screen and (max-width: 1366px) {
      left: 33px;
    }
  }
}

.slider_mac .slick-arrow.slick-next {
  right: -45px;
  top: 50%;
  margin-top: -45px;
  &::after {
    position: relative;
    display: block;
    left: 36px;
    content: " ";
    background: url("../images/icons/next_arrow.svg") 0 0 no-repeat;
    height: 30px;
    width: 18px;
    @media screen and (max-width: 1366px) {
      left: 33px;
    }
  }
}